import { routes } from "../App";
import Cookies from "js-cookie";

function updateOptions(options: RequestInit) {
  const existingToken = localStorage.getItem("token");

  const update = { ...options };
  update.headers = {
    Application: process.env.REACT_APP_APPLICATION_NAME ?? "",
    Authorization: existingToken ? `Bearer ${existingToken}` : "", // Anonymous authorization header has been removed
    ...update.headers,
  };

  return update;
}

export default async function fetcher(url: string, options?: RequestInit) {
  const response = await fetch(url, {
    credentials: "include",
    ...updateOptions(options || {}),
  });
  if (response.status === 403) {
    localStorage.removeItem("token");
    Cookies.remove("jwt");
    window.location.replace(routes.login.path);
  }
  return response;
}
