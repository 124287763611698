import { LogoContainer } from "../../routes/Services/components/CardService";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Plan } from "../../routes/Services/utils/intefaces/Plan";
import { FieldIcon } from "../../routes/Services/utils/intefaces/FieldIcon";
import { renderStars } from "../../routes/Services/utils/getStars";
import { theme } from "../../theme";
import Button from "../Button";
import useGetThemePath from "../../hooks/useGetThemePath";
import { OverviewDataModel } from "./AllServices";
import useViewport from "../../hooks/useViewport";

type ServicesTableProps = {
  setOpenWorkflowSubscriptionModal: (value: boolean) => void;
  setOpenSubscribeModal: (value: boolean) => void;
  setModalOverviewData: (param: OverviewDataModel) => void;
  selectedTypeServiceFilter: string;
  isUserRatingEnabled: boolean;
  sortedAndFilteredPlans: Array<Plan>;
};

export default function ServicesTable({
  setOpenWorkflowSubscriptionModal,
  setModalOverviewData,
  setOpenSubscribeModal,
  selectedTypeServiceFilter,
  isUserRatingEnabled,
  sortedAndFilteredPlans,
}: ServicesTableProps) {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { isMobile } = useViewport();

  function handleSetModalOverviewData(plan: Plan) {
    const data = {
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    };
    setModalOverviewData(data);
  }

  const calcStarsAverage = (ratings_sum: string, total_reviews: string) => {
    const reviewsAverage =
      ratings_sum && Number(total_reviews) !== 0
        ? (Number(ratings_sum) / Number(total_reviews)).toString()
        : "0";
    return renderStars(reviewsAverage);
  };

  if (isMobile) {
    return (
      <Box
        sx={{
          marginTop: "18px",
          overflow: "auto",
          overflowY: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "left" }}>Plan</TableCell>
              <TableCell style={{ textAlign: "left" }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAndFilteredPlans.map((plan, index) => {
              const cyRow = `service-table-row-${index}`;
              return (
                <TableRow
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                  data-cy={cyRow}
                >
                  <TableCell
                    style={{ textAlign: "left", padding: 12 }}
                    data-cy={`${cyRow}-provider`}
                  >
                    <Box display="flex" flexDirection="column" gap="14px">
                      <div>
                        {plan.planName}

                        {selectedTypeServiceFilter === "Internet Services" &&
                          window.innerWidth >= 1140 && (
                            <>
                              <TableCell align="left" data-cy={`${cyRow}-down`}>
                                {plan.fields?.Down}
                              </TableCell>
                              <TableCell align="left" data-cy={`${cyRow}-up`}>
                                {plan.fields?.Up}
                              </TableCell>
                            </>
                          )}
                      </div>
                      <div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Provider: </span>
                          {plan.providerName}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Type: </span>
                          {plan.name}
                        </div>
                      </div>
                      <div>
                        <Box
                          component="button"
                          onClick={() => {
                            handleSetModalOverviewData(plan);
                            setOpenSubscribeModal(true);
                          }}
                          sx={{
                            width: "100px",
                            height: "20px",
                            fontSize: "16px",
                            border: "none",
                            backgroundColor: "transparent",
                            color: "#1D19E0",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: 0,
                            textAlign: "left",
                          }}
                          data-cy={`${cyRow}-view-more`}
                        >
                          View More
                        </Box>
                      </div>
                      <div>
                        {" "}
                        <Button
                          text="Subscribe"
                          onClick={() => {
                            handleSetModalOverviewData(plan);
                            setOpenWorkflowSubscriptionModal(true);
                          }}
                          sx={{
                            width: "120px",
                            height: "20px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            background: "white",
                            padding: "14px 10px",
                            border: `1px solid ${colors.buttons.outline.border}`,
                            color: colors.textPrimary.heading,
                            textTransform: "initial",
                            ":hover": {
                              border: `1px solid ${colors.buttons.outline.border}`,
                              background: "white",
                            },
                            fontWeight: 600,
                          }}
                          data-cy={`${cyRow}-subscribe`}
                        />
                      </div>
                    </Box>
                  </TableCell>
                  {/* <TableCell align="left" data-cy={`${cyRow}-name`}>
                    <div>{plan.planName}</div>
                  </TableCell>
                  <TableCell align="left" data-cy={`${cyRow}-type`}>
                    {plan.name}
                  </TableCell> */}
                  {/* {selectedTypeServiceFilter === "Internet Services" &&
                    window.innerWidth >= 1140 && (
                      <>
                        <TableCell align="left" data-cy={`${cyRow}-down`}>
                          {plan.fields?.Down}
                        </TableCell>
                        <TableCell align="left" data-cy={`${cyRow}-up`}>
                          {plan.fields?.Up}
                        </TableCell>
                      </>
                    )} */}
                  <TableCell align="left" data-cy={`${cyRow}-price`}>
                    ${plan.fields?.Price}
                  </TableCell>
                  {/* {isUserRatingEnabled ? (
                    <TableCell align="left" data-cy={`${cyRow}-rating`}>
                      {stars}
                    </TableCell>
                  ) : null}
                  <TableCell align="center" data-cy={`${cyRow}-details`}>
                    <Box
                      component="button"
                      onClick={() => {
                        handleSetModalOverviewData(plan);
                        setOpenSubscribeModal(true);
                      }}
                      sx={{
                        width: "100px",
                        height: "20px",
                        fontSize: "12px",
                        padding: 0,
                        border: "none",
                        backgroundColor: "transparent",
                        color: "#1D19E0",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      data-cy={`${cyRow}-view-more`}
                    >
                      View More
                    </Box>
                  </TableCell>
                  <TableCell align="left" data-cy={`${cyRow}-action`}>
                    <Button
                      text="Subscribe"
                      onClick={() => {
                        handleSetModalOverviewData(plan);
                        setOpenWorkflowSubscriptionModal(true);
                      }}
                      sx={{
                        width: "120px",
                        height: "20px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        background: "white",
                        padding: "14px 10px",
                        margin: "auto",
                        border: `1px solid ${colors.buttons.outline.border}`,
                        color: colors.textPrimary.heading,
                        textTransform: "initial",
                        ":hover": {
                          border: `1px solid ${colors.buttons.outline.border}`,
                          background: "white",
                        },
                        fontWeight: 600,
                      }}
                      data-cy={`${cyRow}-subscribe`}
                    />
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: "38px",
        marginLeft: "32px",
        marginRight: "12px",
        overflow: "auto",
        overflowY: "hidden",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "left" }}>Provider</TableCell>
            <TableCell style={{ textAlign: "left" }}>Name</TableCell>
            <TableCell style={{ textAlign: "left" }}>Type</TableCell>
            {selectedTypeServiceFilter === "Internet Services" &&
              window.innerWidth >= 1140 && (
                <>
                  <TableCell style={{ textAlign: "left" }}>Down</TableCell>
                  <TableCell style={{ textAlign: "left" }}>Up</TableCell>
                </>
              )}
            <TableCell style={{ textAlign: "left" }}>Price</TableCell>
            {isUserRatingEnabled ? (
              <TableCell style={{ textAlign: "left" }}>
                Average Rating
              </TableCell>
            ) : null}
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAndFilteredPlans.map((plan, index) => {
            const stars = calcStarsAverage(
              plan.ratings_sum,
              plan.total_reviews
            );
            const cyRow = `service-table-row-${index}`;
            return (
              <TableRow
                key={index}
                style={{
                  cursor: "pointer",
                }}
                data-cy={cyRow}
              >
                <TableCell
                  style={{ textAlign: "left", padding: 0 }}
                  data-cy={`${cyRow}-provider`}
                >
                  <LogoContainer width="140" height="63">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
                      alt=""
                    />
                  </LogoContainer>
                </TableCell>
                <TableCell align="left" data-cy={`${cyRow}-name`}>
                  {plan.planName}
                </TableCell>
                <TableCell align="left" data-cy={`${cyRow}-type`}>
                  {plan.name}
                </TableCell>
                {selectedTypeServiceFilter === "Internet Services" &&
                  window.innerWidth >= 1140 && (
                    <>
                      <TableCell align="left" data-cy={`${cyRow}-down`}>
                        {plan.fields?.Down}
                      </TableCell>
                      <TableCell align="left" data-cy={`${cyRow}-up`}>
                        {plan.fields?.Up}
                      </TableCell>
                    </>
                  )}
                <TableCell align="left" data-cy={`${cyRow}-price`}>
                  ${plan.fields?.Price}
                </TableCell>
                {isUserRatingEnabled ? (
                  <TableCell align="left" data-cy={`${cyRow}-rating`}>
                    {stars}
                  </TableCell>
                ) : null}
                <TableCell align="center" data-cy={`${cyRow}-details`}>
                  <Box
                    component="button"
                    onClick={() => {
                      handleSetModalOverviewData(plan);
                      setOpenSubscribeModal(true);
                    }}
                    sx={{
                      width: "100px",
                      height: "20px",
                      fontSize: "12px",
                      padding: 0,
                      border: "none",
                      backgroundColor: "transparent",
                      color: "#1D19E0",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    data-cy={`${cyRow}-view-more`}
                  >
                    View More
                  </Box>
                </TableCell>
                <TableCell align="left" data-cy={`${cyRow}-action`}>
                  <Button
                    text="Subscribe"
                    onClick={() => {
                      handleSetModalOverviewData(plan);
                      setOpenWorkflowSubscriptionModal(true);
                    }}
                    sx={{
                      width: "120px",
                      height: "20px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      background: "white",
                      padding: "14px 10px",
                      margin: "auto",
                      border: `1px solid ${colors.buttons.outline.border}`,
                      color: colors.textPrimary.heading,
                      textTransform: "initial",
                      ":hover": {
                        border: `1px solid ${colors.buttons.outline.border}`,
                        background: "white",
                      },
                      fontWeight: 600,
                    }}
                    data-cy={`${cyRow}-subscribe`}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
