import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { ServiceInfoBody } from "../api/interfaces/ServiceInfo";
import fetcher from "../utils/fetchWrapper";

const getPlans = async (accountType: string | undefined) => {
  let requestUrl =
    `${endpoints.getAllServicesWithPlans}?loading=Services.Provider.Contacts.Location,Services.Plans&filter=` +
    "Services.display_type eq PUBLIC::" +
    "Services.status eq ACTIVE::" +
    "Services.Provider.status eq ACTIVE::" +
    "Services.Plans.status eq ACTIVE";
  if (accountType) {
    requestUrl += `::Services.account_types like "${accountType}"`;
  }
  const response = await fetcher(requestUrl);
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetPlans = (
  accountType?: string | undefined,
  options?: UseQueryOptions<
    ServiceInfoBody,
    Error,
    ServiceInfoBody,
    Array<string>
  >
): UseQueryResult<any, Error> =>
  useQuery([accountType ?? ""], () => getPlans(accountType), options);
