import { useMutation, UseMutationOptions } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { LoginUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const logoutUser = async () => {
  const response = await fetcher(`${endpoints.logoutUser}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useLogoutUser = (options?: UseMutationOptions<Error, LoginUser>) =>
  useMutation(() => logoutUser(), options);
