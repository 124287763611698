import { Box, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { useEffect } from "react";
import Loader from "../../../../../components/Loader";
import { toast } from "react-toastify";
import { Contacts } from "../../../../../api/interfaces/Contacts";

type PostJsonType = {
  service_id?: string;
  subscription_id: string;
  service: {
    Down: string;
    Name: string;
    Price: string;
    Up: string;
  };
  contacts?: Contacts[];
};

const LoaderContent = ({
  modalOverviewData,
  closeDialog,
}: {
  modalOverviewData: OverviewDataModel;
  closeDialog: Function;
}) => {
  useEffect(() => {
    const redirectToNewTabWithPostJSON = (
      url: string,
      data: OverviewDataModel
    ) => {
      const body: PostJsonType = {
        contacts: data.Contacts,
        service: {
          Down: data.fields.Down,
          Name: data.fields.Name,
          Price: data.fields.Price,
          Up: data.fields.Up,
        },
        service_id: data.service_id,
        subscription_id: data.planId,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
        body: JSON.stringify({
          ...body,
        }),
      })
        .then(() => {
          const newTab = window.open();
          if (newTab) newTab.location.href = url;
        })
        .catch(() => {
          toast.error("Failed to redirect to the provider's website");
          closeDialog();
        });
    };
    const redirectToNewTabAfterDelay = () => {
      setTimeout(() => {
        if (modalOverviewData.subscription_redirect_url) {
          // Call the function to open a new tab with POST request
          redirectToNewTabWithPostJSON(
            modalOverviewData.subscription_redirect_url,
            modalOverviewData
          );
        }
      }, 2000);
    };

    redirectToNewTabAfterDelay();
  }, [modalOverviewData]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(32)}
        fontFamily="Inter"
        color="#253331"
        fontWeight="bold"
        pt={"95px"}
      >
        You are now subscribed to
      </Typography>
      <Box
        sx={{
          aspectRatio: 207 / 63,
          maxWidth: 400,
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
          alt="Provider Logo"
          role="presentation"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Inter"
        color="#253331"
        fontWeight="600"
        pt={"25px"}
      >
        {modalOverviewData.fields.Name}
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <Loader />
      </Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Inter"
        color="#253331"
        fontWeight="500"
        mt={"39px"}
      >
        You are now being redirected to{" "}
        {modalOverviewData.providerName || "the provider"}'s website
      </Typography>
    </Box>
  );
};

export default LoaderContent;
