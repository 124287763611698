import { useCallback, useEffect, useMemo, useState } from "react";
import CardService from "../../routes/Services/components/CardService";
import { styled } from "@mui/material";
import { Plan } from "../../routes/Services/utils/intefaces/Plan";
import WorkflowSubscriptionModal from "../../routes/Services/components/SubscriptionModal/WorkflowSubscriptionModal";
import SubscribeModal from "../../routes/Services/components/SubscribeModal";
import TableServices from "./TableServices";
import { typesOptions } from "./constants";
import { SortFilterLabel } from "./interfaces";
import { applyExtraFilters } from "../../routes/Services/utils/extraFIlters";

import { useSearchParams } from "react-router-dom";
import { useGetConfig } from "../../hooks/useGetConfig";
import ServicesTable from "./ServicesTable";
import { FieldIcon } from "../../routes/Services/utils/intefaces/FieldIcon";
import { Contacts } from "../../api/interfaces/Contacts";
import { ServicePlanFCC } from "../../api/interfaces/Plan";

export const onSubscribeService = (param: Partial<OverviewDataModel>) => {
  console.log(param);
};

interface AllServicesProps {
  plansList: Array<Plan>;
}

export interface OverviewDataModel {
  name: string;
  planName: string;
  providerName: string;
  service_id?: string;
  provider_id: string; //used for img
  fields: {
    Down: string;
    LinkSpeed: number;
    Name: string; // same than plan name
    Price: string;
    Up: string;
  };
  description: string;
  planId: string; // used to get reviews
  ratings_sum?: string;
  total_reviews?: string;
  email?: string;
  phone?: string;
  support_url?: string;
  subscription_redirect_url?: string;
  fields_icons?: Array<FieldIcon>;
  Contacts?: Array<Contacts>;
  fcc?: ServicePlanFCC;
}

const initial_state = {
  fields: {
    Down: "",
    LinkSpeed: 0,
    Name: "",
    Price: "",
    Up: "",
  },
  description: "",
  name: "",
  planName: "",
  provider_id: "",
  planId: "",
  ratings_sum: "",
  total_reviews: "",
  fields_icons: [],
  providerName: "",
};

const CardContainer = styled("div")`
  margin-top: 43.5px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  justify-items: center;
  align-items: center;
  gap: 3rem 1rem;
`;

const AllServices = ({ plansList }: AllServicesProps) => {
  const [filteredPlansList, setFilteredPlansList] =
    useState<Array<Plan>>(plansList);

  const [urlSearchParams] = useSearchParams();
  const filterBy = decodeURI(urlSearchParams.get("filter") ?? "");

  const [sortFilter, setSortFilter] = useState<SortFilterLabel | null>(null);
  const [options, setOptions] = useState(typesOptions);
  const [selectedTypeServiceFilter, setSelectedTypeServiceFilter] =
    useState<string>(filterBy ? filterBy : "All Services");

  const { data } = useGetConfig();

  const isUserRatingEnabled = Boolean(
    data?.data.config.enabled_features.USER_REVIEWS.enabled
  );

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openWorkflowSubscriptionModal, setOpenWorkflowSubscriptionModal] =
    useState(false);
  const [modalOverviewData, setModalOverviewData] = useState<OverviewDataModel>(
    () => initial_state
  );

  const sortedAndFilteredPlans = useMemo(() => {
    return applyExtraFilters(
      filteredPlansList,
      selectedTypeServiceFilter,
      sortFilter
    );
  }, [sortFilter, selectedTypeServiceFilter, filteredPlansList]);

  const handleChangeTypeOfView = (idx: number) => {
    const changedStatusList = options.map((val, i) => ({
      ...val,
      active: i === idx,
    }));

    setOptions(changedStatusList);
  };

  const handleInputChange = useCallback(
    (val: string | null = null) => {
      let filteredPlans: Plan[] = plansList;
      if (val) {
        filteredPlans = plansList.filter(
          (v: Plan) =>
            v.planName.toLowerCase().includes(val.toLowerCase()) ||
            v.name.toLowerCase().includes(val.toLowerCase()) ||
            (v.providerName &&
              v.providerName.toLowerCase().includes(val.toLowerCase())) ||
            (v.fields &&
              v.fields.Price &&
              v.fields.Price.toLowerCase().includes(val.toLowerCase()))
        );
      }
      setFilteredPlansList(filteredPlans);
    },
    [plansList]
  );

  useEffect(() => {
    handleInputChange();
  }, [handleInputChange]);

  function renderContent() {
    // if user select tile/card view
    // by default we have tile/card view selected
    if (options.at(0)?.active) {
      return (
        <CardContainer data-cy="allServicesContainer">
          {sortedAndFilteredPlans?.map((plan, idx) => (
            <CardService
              key={`${plan.id}`}
              setOpenSubscribeModal={() => setOpenSubscribeModal(true)}
              plan={plan}
              setModalOverviewData={setModalOverviewData}
              setOpenWorkflowSubscriptionModal={() =>
                setOpenWorkflowSubscriptionModal(true)
              }
              dataCy={`card-service-${idx}`}
            />
          ))}
        </CardContainer>
      );
    }

    // if user select table/list view
    return (
      <ServicesTable
        isUserRatingEnabled={isUserRatingEnabled}
        sortedAndFilteredPlans={sortedAndFilteredPlans}
        setOpenWorkflowSubscriptionModal={setOpenWorkflowSubscriptionModal}
        setOpenSubscribeModal={setOpenSubscribeModal}
        setModalOverviewData={setModalOverviewData}
        selectedTypeServiceFilter={selectedTypeServiceFilter}
      />
    );
  }

  return (
    <TableServices
      options={options}
      handleChangeTypeOfView={handleChangeTypeOfView}
      setSelectedTypeServiceFilter={setSelectedTypeServiceFilter}
      setSortFilter={setSortFilter}
      sortFilter={sortFilter}
      handleInputChange={handleInputChange}
      isMyTabsActive={true}
      activeServiceFilter={filterBy}
    >
      <>
        {renderContent()}
        <SubscribeModal
          isUserRatingEnabled={isUserRatingEnabled}
          open={openSubscribeModal}
          handleClose={() => setOpenSubscribeModal(false)}
          modalOverviewData={modalOverviewData}
          setOpenWorkflowSubscriptionModal={setOpenWorkflowSubscriptionModal}
        />
        <WorkflowSubscriptionModal
          open={openWorkflowSubscriptionModal}
          modalOverviewData={modalOverviewData}
          handleClose={() => {
            setModalOverviewData(initial_state);
            setOpenWorkflowSubscriptionModal(false);
            setOpenSubscribeModal(false);
          }}
        />
      </>
    </TableServices>
  );
};

export default AllServices;
