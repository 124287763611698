import { Box, Typography, Link } from "@mui/material";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import getREMFromPX from "../../../utils/getREMFromPX";
import useGetThemePath from "../../../hooks/useGetThemePath";
import { theme } from "../../../theme";

const BroadbandLabel = ({
  modalOverviewData,
}: {
  modalOverviewData: OverviewDataModel;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const serviceplan = modalOverviewData;

  if (!serviceplan || !serviceplan.fcc || Array.isArray(serviceplan.fcc)) {
    return (
      <Box
        sx={{
          textAlign: "center",
          padding: "16px",
          mt: "1rem",
        }}
      >
        Plan Details Not Found!
      </Box>
    );
  }

  function renderMonthlyOneTimeFees() {
    const monthlyFees =
      serviceplan?.fcc?.charges_terms?.monthly?.items?.filter(
        (item) => item.type !== "One-Time Purchase Fee"
      ) ?? [];
    const oneTimeFees =
      serviceplan?.fcc?.charges_terms?.monthly?.items?.filter(
        (item) => item.type === "One-Time Purchase Fee"
      ) ?? [];

    return (
      <Box>
        <Typography fontSize={getREMFromPX(16)} fontWeight="600">
          Provider Monthly Fees
        </Typography>
        {monthlyFees?.map((item, index) => {
          return (
            <Typography
              key={index + item.description}
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              fontSize={getREMFromPX(16)}
              fontWeight="600"
              py="3px"
            >
              <Box sx={{ maxWidth: "70%" }}>{item.description}</Box>
              <Box>{item.value}</Box>
            </Typography>
          );
        })}
        <Typography fontSize={getREMFromPX(16)} mt="1rem" fontWeight="600">
          One-Time Purchase Fees
        </Typography>
        {oneTimeFees?.map((item, index) => {
          return (
            <Typography
              key={index + item.description}
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              fontSize={getREMFromPX(16)}
              fontWeight="600"
              py="3px"
            >
              <Box sx={{ maxWidth: "70%" }}>{item.description}</Box>
              <Box>{item.value}</Box>
            </Typography>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingTop={getREMFromPX(16)}
      flexDirection="column"
      color={"black"}
      sx={{
        border: "8px solid",
        padding: "16px",
        width: "448px",
        mx: "auto",
      }}
    >
      <Typography
        fontSize={getREMFromPX(48)}
        fontWeight="600"
        sx={{
          textDecoration: "underline",
          textUnderlineOffset: 8,
        }}
      >
        Broadband Facts
      </Typography>
      <Typography fontSize={getREMFromPX(24)} fontWeight="600">
        {modalOverviewData.providerName}
      </Typography>
      <Typography fontSize={getREMFromPX(16)} fontWeight="600">
        {modalOverviewData.name} - {modalOverviewData.planName}
      </Typography>
      <Typography fontSize={getREMFromPX(16)} fontWeight="600" mb={2}>
        {serviceplan.description}
      </Typography>
      <Box
        sx={{
          height: "20px",
          width: "100%",
          backgroundColor: "black",
        }}
      />

      <Typography
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontSize={getREMFromPX(20)}
        fontWeight="600"
        py="10px"
      >
        <Box>Monthly Price</Box>
        <Box>{serviceplan.fcc?.cost.monthly_price || "$0.00"}</Box>
      </Typography>

      <Box
        sx={{
          height: "8px",
          width: "100%",
          backgroundColor: "black",
        }}
      />
      <Box py="6px" borderBottom="2px solid">
        <Typography
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          fontSize={getREMFromPX(16)}
          fontWeight="600"
          py="3px"
        >
          <Box sx={{ maxWidth: "70%" }}>
            This monthly price is an introductory rate
          </Box>
          <Box>
            {serviceplan.fcc?.agreement_details.has_introductory_rate
              ? "Yes"
              : "No"}
          </Box>
        </Typography>

        <Typography
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          fontSize={getREMFromPX(16)}
          fontWeight="600"
          py="3px"
        >
          <Box sx={{ maxWidth: "70%" }}>Time the introductory rate applies</Box>
          <Box>
            {serviceplan.fcc?.agreement_details.introductory_rate_interval ||
              "-"}
          </Box>
        </Typography>
        <Typography
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          fontSize={getREMFromPX(16)}
          fontWeight="600"
          py="3px"
        >
          <Box sx={{ maxWidth: "70%" }}>Link to Terms of Contract</Box>
          <Link
            href={serviceplan.fcc?.agreement_details.contract_terms_url || "#"}
            sx={{
              color: colors.textPrimary.heading,
              textDecoration: "none",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {serviceplan.fcc?.agreement_details.contract_terms_url || "-"}
          </Link>
        </Typography>

        <Typography
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          fontSize={getREMFromPX(16)}
          fontWeight="600"
          py="3px"
        >
          <Box sx={{ maxWidth: "70%" }}>Contract Length</Box>
          <Box>
            {serviceplan.fcc?.agreement_details.length_of_your_contract || "-"}
          </Box>
        </Typography>

        <Typography
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          fontSize={getREMFromPX(16)}
          fontWeight="600"
          py="3px"
        >
          <Box sx={{ maxWidth: "70%" }}>Price after introductory rate</Box>
          <Box>
            {serviceplan.fcc?.agreement_details.price_after_introductory_rate ||
              "-"}
          </Box>
        </Typography>
      </Box>
      {serviceplan?.fcc?.charges_terms?.monthly.items.length ? (
        <Box py="10px" borderBottom="2px solid">
          <Typography fontSize={getREMFromPX(16)} fontWeight="600">
            Additional Charges & Terms
          </Typography>
          <Box px="16px">
            {serviceplan?.fcc?.charges_terms?.monthly
              ? renderMonthlyOneTimeFees()
              : null}
          </Box>
        </Box>
      ) : null}

      <Box py="10px" borderBottom="2px solid">
        <Typography fontSize={getREMFromPX(16)} fontWeight="600">
          Speeds Provided with Plan
        </Typography>
        <Box px="16px">
          {serviceplan?.fcc?.speeds ? (
            <Box>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Typical Download Speed</Box>
                <Box>{serviceplan.fcc.speeds.typical_download || "-"}</Box>
              </Typography>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Typical Upload Speed</Box>
                <Box>{serviceplan.fcc.speeds.typical_upload || "-"}</Box>
              </Typography>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Typical Latency</Box>
                <Box>{serviceplan.fcc.speeds.typical_latency || "-"}</Box>
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box py="10px" borderBottom="2px solid">
        <Typography fontSize={getREMFromPX(16)} fontWeight="600">
          Data Usage
        </Typography>
        <Box px="16px">
          {serviceplan?.fcc?.included ? (
            <Box>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Included with Monthly Price</Box>
                <Box>{serviceplan?.fcc?.included.data_cap || "-"}</Box>
              </Typography>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Charges for Additional Usage</Box>
                <Box>
                  {serviceplan?.fcc?.included.additional_data_price || "-"}
                </Box>
              </Typography>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Data usage details</Box>
                <Link
                  href={serviceplan?.fcc?.included.data_usage_url || "#"}
                  sx={{
                    color: colors.textPrimary.heading,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {serviceplan?.fcc?.included.data_usage_url || "-"}
                </Link>
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {serviceplan?.fcc?.privacy?.policy_url ? (
        <Box py="10px">
          <Typography fontSize={getREMFromPX(16)} fontWeight="600">
            Privacy Policy
          </Typography>
          <Box sx={{ paddingLeft: "16px", paddingRight: "16px" }}></Box>
          <Link
            href={serviceplan?.fcc?.privacy?.policy_url || "#"}
            sx={{
              color: colors.textPrimary.heading,
              textDecoration: "none",
              paddingLeft: "16px",
              paddingRight: "16px",
              fontSize: getREMFromPX(16),
              fontWeight: 600,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {serviceplan?.fcc?.privacy?.policy_url}
          </Link>
        </Box>
      ) : null}
      <Box
        sx={{
          height: "20px",
          width: "100%",
          backgroundColor: "black",
        }}
      />
      <Box py="10px" borderBottom="2px solid">
        <Typography fontSize={getREMFromPX(16)} fontWeight="600">
          Customer Support
        </Typography>
        <Box px="16px">
          <Box>
            {serviceplan?.fcc?.support?.phone ? (
              <Typography
                display="flex"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Phone : </Box>
                <Link
                  href={`tel:${serviceplan?.fcc?.support?.phone || ""}`}
                  sx={{
                    color: colors.textPrimary.heading,
                    textDecoration: "none",
                    ml: 1,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {serviceplan?.fcc?.support?.phone}
                </Link>
              </Typography>
            ) : null}

            {serviceplan?.fcc?.support?.url ? (
              <Typography
                display="flex"
                alignItems="start"
                fontSize={getREMFromPX(16)}
                fontWeight="600"
                py="3px"
              >
                <Box sx={{ maxWidth: "70%" }}>Website : </Box>
                <Link
                  href={`${serviceplan?.fcc?.support?.url || "#"}`}
                  sx={{
                    color: colors.textPrimary.heading,
                    textDecoration: "none",
                    ml: 1,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {serviceplan?.fcc?.support?.url}
                </Link>
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BroadbandLabel;
